const permissionsTableHeadersA = [
  {
    text: "Gerencias",
    align: "start",
    sortable: false,
    value: "management_name",
    class: "primary--text",
  },
  {
    text: "Acción",
    align: "right",
    sortable: false,
    value: "actions",
    class: "primary--text",
  },
];

const permissionsTableHeadersB = [
  {
    text: "Aplicación",
    align: "start",
    sortable: false,
    value: "application_name",
    class: "primary--text",
  },
  {
    text: "Perfil",
    align: "start",
    sortable: false,
    value: "profile_name",
    class: "primary--text",
  },
  {
    text: "Acción",
    align: "right",
    sortable: false,
    value: "actions",
    class: "primary--text",
  },
];

const permissionsTableHeadersC = [
  {
    text: "Gerencia",
    align: "start",
    sortable: false,
    value: "management_name",
    class: "primary--text",
  },
  {
    text: "Centro de costo",
    align: "start",
    sortable: false,
    value: "costCenter_name",
    class: "primary--text",
  },
  {
    text: "PEP",
    align: "start",
    sortable: false,
    value: "costCenter_pep",
    class: "primary--text",
  },
  {
    text: "Acción",
    align: "right",
    sortable: false,
    value: "actions",
    class: "primary--text",
  },
];

const usersTableHeaders = [
  {
    text: "Nombres",
    align: "start",
    sortable: false,
    value: "name",
    class: "primary--text",
  },
  {
    text: "Apellido P.",
    align: "start",
    sortable: false,
    value: "last_name_father",
    class: "primary--text",
  },
  {
    text: "Id de usuario",
    align: "start",
    sortable: false,
    value: "id",
    class: "primary--text"
  },
  {
    text: "Usuario",
    align: "start",
    sortable: false,
    value: "username",
    class: "primary--text",
  },
  {
    text: "Correo electrónico",
    sortable: false,
    value: "email_corp",
    class: "primary--text",
  },
  {
    text: "Activo",
    sortable: false,
    value: "active",
    class: "primary--text",
  },
  {
    text: "Acciones",
    value: "actions",
    sortable: false,
    class: "primary--text",
  },
];

export {
  permissionsTableHeadersA,
  permissionsTableHeadersB,
  permissionsTableHeadersC,
  usersTableHeaders,
};
