<template>
	<v-dialog v-model="dialog" max-width="700px" persistent>
		<v-card>
			<v-card-title>
				<span class="text-body-1 primary--text">Asignar Acceso</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12" sm="12" md="12">
							<v-autocomplete
								:items="managementsProcessed"
								item-value="management_id"
								item-text="management_name"
								label="Gerencias"
								outlined
								hide-details="auto"
								v-model="managementSelected"
								multiple
								class="no-wrap"
								@change="listenChangeManagement"
								:menu-props="{ maxHeight: '400' }"
							></v-autocomplete>

							<!-- <v-select
								v-model="managementSelected"
								:items="managements"
								@change="
									(v) => {
										listenChangeManagement(v);
									}
								"
								item-value="management_id"
								item-text="management_name"
								label="Gerencias"
							></v-select> -->
						</v-col>
						<v-col cols="12" sm="12" md="12">
							<v-autocomplete
								v-model="costCenterSelected"
								:items="costCenterFilter"
								item-value="id"
								label="Centro de Costo"
								outlined
								hide-details="auto"
								multiple
								class="no-wrap"
								:loading="isLoadingCeco"
								:disabled="managementSelected ? false : true"
								@change="listenChangeCostCenter"
							>
								<template slot="item" slot-scope="{ item }">
									<v-checkbox
										:label="item.text"
										:input-value="
											costCenterSelected && costCenterSelected.includes(item.id) ? 1 : 0
										"
										readonly
									></v-checkbox>
								</template>
							</v-autocomplete>

							<!-- <v-select
								v-model="costCenterSelected"
								:items="costCenters"
								:menu-props="{ maxHeight: '400' }"
								:loading="isLoadingCeco"
								:disabled="isLoadingCeco"
								item-value="id"
								label="Centro de Costo"
							>
								<template v-slot:selection="data">
									{{ data.item.pep ? data.item.pep : data.item.costCenter }} - {{ data.item.name }}
								</template>
								<template v-slot:item="data">
									{{ data.item.pep ? data.item.pep : data.item.costCenter }} - {{ data.item.name }}
								</template>
							</v-select> -->
						</v-col>
						<v-col cols="12" sm="12" md="12">
							<v-btn
								color="primary"
								class="my-3 text-none px-8"
								:disabled="!isActiveAdd"
								:dark="isActiveAdd"
								@click="addCostCenters()"
								cli
								rounded
								block
							>
								Agregar
							</v-btn>
						</v-col>
						<v-col cols="12" sm="12" md="12">
							<v-data-table
								:headers="headers"
								:items="costCentersTable"
								:items-per-page="-1"
								item-key="id"
								class="elevation-1 mt-4"
								hide-default-footer
							>
								<template v-slot:[`item.actions`]="{ item }">
									<v-icon class="text-md-body-1" @click="removeItem(item)"> mdi-delete </v-icon>
								</template>
							</v-data-table>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click="close()"> Cancelar </v-btn>
				<v-btn color="blue" text @click="save()"> Guardar </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { $EventBus } from '@/main';
import { permissionsTableHeadersC } from '@/assets/js/tableHeader.js';

export default {
	name: 'EditPermissionDialog',

	props: {
		application: Object,
		managements: Array,
		company_id: Number,
		allManagements: Array,
	},

	data: () => ({
		headers: permissionsTableHeadersC,
		managementSelected: null,
		costCenterSelected: null,
		costCentersTable: [],
		dialog: false,
		isLoadingCeco: false,
	}),
	computed: {
		costCenters() {
			let items = [{ id: null, costCenter: 'Todos los centros de costo' }];
			let costCenters = this.$store.getters['costCenters/getCostCentersList'];
			if (Array.isArray(costCenters) && costCenters.length > 0) {
				//TODO: cambiar a la nueva estructura
				costCenters = costCenters.filter((c) => !!c.pep);
				items = items.concat(costCenters);
			}
			return items;
		},
		costCenterFilter() {
			let items = [{ id: null, text: 'Todos los centros de costo' }];
			let costCenters = this.$store.getters['costCenters/getCostCentersList'];
			let costCenterExist = this.costCentersTable.map((item) => item.costCenter_id);
		
			//
			
			if(this.managementSelected?.includes('Todas las gerencias')){
				for (let management of this.managements) {
					items.push({ header: management.management_name, text: management.management_name });

						costCenters.forEach((itemCCO) => {
							if (
								itemCCO.active &&
								itemCCO.pep &&
								!costCenterExist.includes(itemCCO.id) &&
								itemCCO.management_id == management.management_id
							) {
								
								items.push(
									Object.assign(
										{ ...itemCCO },
										{
											text:
												(itemCCO.pep ? itemCCO.pep : itemCCO.costCenter) + ' - ' + itemCCO.name,
										}
									)
								);
							}
						});
				}
			}else{
				this.managements.forEach((management) => {
					if (
						!this.managementSelected ||
						this.managementSelected.length == 0 ||
						this.managementSelected.includes(management.management_id)
					) {
					
						items.push({ header: management.management_name, text: management.management_name });

						costCenters.forEach((itemCCO) => {
							if (
								itemCCO.active &&
								itemCCO.pep &&
								!costCenterExist.includes(itemCCO.id) &&
								itemCCO.management_id == management.management_id
							) {
								items.push(
									Object.assign(
										{ ...itemCCO },
										{
											text:
												(itemCCO.pep ? itemCCO.pep : itemCCO.costCenter) + ' - ' + itemCCO.name,
										}
									)
								);
							}
						});
					}
				});
			}

			// if (
			// 	this.managements &&
			// 	this.managements.length &&
			// 	this.managements.length === 1 &&
			// 	!this.managements[0].management_id &&
			// 	this.managements[0].management_name === 'Todas las gerencias'
			// ) {
		
			// 	for (let management of this.allManagements) {
			// 		if (
			// 			!this.managementSelected ||
			// 			this.managementSelected.length == 0 ||
			// 			this.managementSelected.includes(management.management_id) ||
			// 			(this.managementSelected.length === 1 && this.managementSelected[0] === null)
			// 		) {
			// 			items.push({ header: management.name, text: management.name });

			// 			costCenters.forEach((itemCCO) => {
			// 				if (
			// 					itemCCO.active &&
			// 					itemCCO.pep &&
			// 					!costCenterExist.includes(itemCCO.id) &&
			// 					itemCCO.management_id == management.id
			// 				) {
			// 					items.push(
			// 						Object.assign(
			// 							{ ...itemCCO },
			// 							{
			// 								text:
			// 									(itemCCO.pep ? itemCCO.pep : itemCCO.costCenter) + ' - ' + itemCCO.name,
			// 							}
			// 						)
			// 					);
			// 				}
			// 			});
			// 		} else {
			// 			items.push({ header: management.name, text: management.name });

			// 			costCenters.forEach((itemCCO) => {
			// 				if (
			// 					itemCCO.active &&
			// 					itemCCO.pep &&
			// 					!costCenterExist.includes(itemCCO.id) &&
			// 					itemCCO.management_id == management.id
			// 				) {
			// 					items.push(
			// 						Object.assign(
			// 							{ ...itemCCO },
			// 							{
			// 								text:
			// 									(itemCCO.pep ? itemCCO.pep : itemCCO.costCenter) + ' - ' + itemCCO.name,
			// 							}
			// 						)
			// 					);
			// 				}
			// 			});
			// 		}
			// 	}
			// } else {
			// 	this.managements.forEach((management) => {
			// 		if (
			// 			!this.managementSelected ||
			// 			this.managementSelected.length == 0 ||
			// 			this.managementSelected.includes(management.management_id)
			// 		) {
					
			// 			items.push({ header: management.management_name, text: management.management_name });

			// 			costCenters.forEach((itemCCO) => {
			// 				if (
			// 					itemCCO.active &&
			// 					itemCCO.pep &&
			// 					!costCenterExist.includes(itemCCO.id) &&
			// 					itemCCO.management_id == management.management_id
			// 				) {
			// 					items.push(
			// 						Object.assign(
			// 							{ ...itemCCO },
			// 							{
			// 								text:
			// 									(itemCCO.pep ? itemCCO.pep : itemCCO.costCenter) + ' - ' + itemCCO.name,
			// 							}
			// 						)
			// 					);
			// 				}
			// 			});
			// 		}
			// 	});
			// }
	
			return items;
		},
		managementFilter() {
			let items = [];

			if (
				this.managements.length === 1 &&
				!this.managements[0].management_id &&
				this.managements[0].management_name === 'Todas las gerencias'
			) {
				const allManagements = this.allManagements.map((res) => {
					return { management_id: res.id, management_name: res.name };
				});

				allManagements.forEach((management) => {
					items.push(Object.assign({ ...management }, { costCenters: [] }));
				});
			} else {
				this.managements.forEach((management) => {
					items.push(Object.assign({ ...management }, { costCenters: [] }));
				});
			}

			// if(this.costCenterSelected &&  this.costCenterSelected.includes(null) && )

			return items;
		},
		isActiveAdd() {
			const managements = this.managements || [];
			const index = managements.findIndex((e) => e.management_id == null);
			if (index == -1 && this.managementSelected) {
				return true;
			} else if (index >= 0 && this.managementSelected === null) {
				return true;
			} else if (index >= 0 && this.managementSelected && this.managementSelected.includes(null)) {
				return true;
			}
			return false;
		},
		///
		managementsProcessed() {
			return [{ management_id: undefined, management_name: 'Todas las gerencias' }, ...this.managements];
		},
	},

	watch: {
		application(val) {
			if (val && val.application_id) {
				this.dialog = true;
				this.setTable();
				this.checkNullManagements();
			}
		},
		dialog(val) {
			val || this.close();
		},
	},

	methods: {
		setTable() {
			const costCenters = [...this.application?.costCenters] || [];
			this.costCentersTable = costCenters;
		},

		checkNullManagements() {
			const hasNullItem = this.managements.some(({ management_id }) => management_id === null);
			if (hasNullItem) this.listenChangeManagement();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.managementSelected = null;
				this.costCenterSelected = null;
				this.costCentersTable = [];
			});
		},

		save() {
			const params = {
				profile_id: this.application?.profile_id,
				costCenters: [...this.costCentersTable],
			};
			this.$emit('updateAccess', params);
			this.dialog = false;
		},

		// async listenChangeManagement(val) {
		// 	console.log('val',val)
		// 	if (val && val.includes(null)) {
		// 		console.log('aqui en validacion si envia null o undefined')
		// 		val = this.allManagements.map((management) => management.id);
		// 		console.log('val 2',val)
		// 	}

		// 	this.costCenterSelected = null;
		// 	if (val || this.company_id) {
		// 		console.log('aqui si envia valor diferente a null o undefined')
		// 		try {
		// 			this.isLoadingCeco = true;
		// 			const params = val
		// 				? { management_id: val, actives: true }
		// 				: { company_id: this.company_id, actives: true };
		// 			await this.$store.dispatch('costCenters/getCostCentersList', params);
		// 		} finally {
		// 			this.isLoadingCeco = false;
		// 		}
		// 	} else {
		// 		this.$store.dispatch('costCenters/cleanCostCentersList');
		// 	}
		// },

		async listenChangeManagement(val) {
			if (val && val.includes('Todas las gerencias')) {
				const managementIds = this.managements.map((management) => management.management_id);
				this.managementSelected = [...['Todas las gerencias'],...managementIds];
				val = this.managements.map((management) => management.management_id);
			}

			this.costCenterSelected = null;
			if (val || this.company_id) {
				try {
					this.isLoadingCeco = true;
					const params = val
						? { management_id: val, actives: true }
						: { company_id: this.company_id, actives: true };
					await this.$store.dispatch('costCenters/getCostCentersList', params);
				} finally {
					this.isLoadingCeco = false;
				}
			} else {
				this.$store.dispatch('costCenters/cleanCostCentersList');
			}
		},

		showSnack(color = '', text = '') {
			$EventBus.$emit('showSnack', { color, text });
		},

		addCostCenters() {
			if (this.managementSelected && this.managementSelected.includes('Todas las gerencias')) {
				// this.managementSelected = this.allManagements.map((res) => res.id);
				this.managementSelected = this.managements.map((res) => res.management_id);
			}
			
			this.managementFilter.forEach((management) => {
				if (this.managementSelected && this.managementSelected.includes(management.management_id)) {
					this.costCenterFilter.forEach((costCenter) => {
						if (
							costCenter.management_id == management.management_id &&
							this.costCenterSelected &&
							this.costCenterSelected.includes(costCenter.id)
						) {
							const data = {
								management_id: management.management_id,
								management_name: management.management_name,
								costCenter_id: costCenter.id,
								costCenter_name: costCenter.name,
								costCenter_code: costCenter.costCenter,
								costCenter_pep: costCenter.pep,
							};

							const hasSameItem = this.costCentersTable.some((elem) => {
								const checkManagement = elem?.management_id == data?.management_id;
								const checkCostCenter = elem?.costCenter_id == data?.costCenter_id;
								return checkManagement && checkCostCenter;
							});

							if (hasSameItem) {
								this.showSnack('warning', 'No se acepta duplicidad');
							} else {
								let itemsDelete = [];
								if (data.management_id === null) {
									this.costCentersTable = [];
								} else if (data.costCenter_id === null) {
									itemsDelete = this.costCentersTable.filter(
										(e) => e.management_id == data.management_id
									);
								} else if (data.costCenter_id) {
									itemsDelete = this.costCentersTable.filter(
										(e) => e.management_id == data.management_id && e.costCenter_id === null
									);
								}

								for (const item of itemsDelete) {
									const index = this.costCentersTable.findIndex(
										(e) =>
											e.management_id == item.management_id &&
											e.costCenter_id == item.costCenter_id
									);
									this.costCentersTable.splice(index, 1);
								}

								this.costCentersTable.push(data);
							}
						}
					});
				}
			});
			this.costCenterSelected = null;
			/* const foundManagement = this.managements.find((e) => e?.management_id == this.managementSelected);

			const foundCostCenter = this.costCenters.find((e) => e.id == this.costCenterSelected);
			const data = {
				management_id: foundManagement?.management_id,
				management_name: foundManagement?.management_name,
				costCenter_id: foundCostCenter?.id,
				costCenter_name: foundCostCenter.name,
				costCenter_code: foundCostCenter?.costCenter,
				costCenter_pep: foundCostCenter?.pep,
			};

			const hasSameItem = this.costCentersTable.some((elem) => {
				const checkManagement = elem?.management_id == data?.management_id;
				const checkCostCenter = elem?.costCenter_id == data?.costCenter_id;
				return checkManagement && checkCostCenter;
			});

			if (hasSameItem) {
				this.showSnack('warning', 'No se acepta duplicidad');
			} else {
				let itemsDelete = [];
				if (data.management_id === null) {
					this.costCentersTable = [];
				} else if (data.costCenter_id === null) {
					itemsDelete = this.costCentersTable.filter((e) => e.management_id == data.management_id);
				} else if (data.costCenter_id) {
					itemsDelete = this.costCentersTable.filter(
						(e) => e.management_id == data.management_id && e.costCenter_id === null
					);
				}

				for (const item of itemsDelete) {
					const index = this.costCentersTable.findIndex(
						(e) => e.management_id == item.management_id && e.costCenter_id == item.costCenter_id
					);
					this.costCentersTable.splice(index, 1);
				}

				this.costCentersTable.push(data);
				this.costCenterSelected = null;
			} */
		},

		removeItem(item) {
			if (item) {
				const index = this.costCentersTable.findIndex((elem) => elem === item);
				if (index >= 0) this.costCentersTable.splice(index, 1);
			}
		},
		listenChangeCostCenter(value) {
			let selectItem = value.length > 0 ? value[value.length - 1] : null;
			let ids = this.costCenterFilter.filter((item) => item.id).map((item) => item.id);

			if (value.length > 0 && !selectItem) {
				this.costCenterSelected = [...[null], ...ids];
			} else {
				if (value) {
					const index = value.findIndex((elem) => !elem);
					if (index < 0 && value.length == ids.length) {
						this.costCenterSelected = [...[null], ...this.costCenterSelected];
					}

					if (index >= 0 && value.length != ids.length - 1) {
						this.costCenterSelected.splice(index, 1);
					}
				}
			}
		},
	},
};
</script>
