<template>
  <v-alert
    v-if="validationErrors.length"
    border="right"
    colored-border
    type="error"
    elevation="2"
    class="text-caption text-decoration-none"
  >
    <ul>
      <li v-for="(validation, i) in validationErrors" :key="i">
        {{ validation }}
      </li>
    </ul>
  </v-alert>
</template>
<script>
export default {
  name: "ValidationAlert",
  props: {
    validationErrors: Array,
  },
};
</script>
